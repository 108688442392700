var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"buy-chat-license"},[_c('page-title'),(_vm.invoice)?_c('a-card',{attrs:{"size":"small"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('recurrent_license_active_auto_pay', [ _vm.upperFirst(_vm.chatLicense.licenseType) ]).toString())+" ")]},proxy:true}],null,false,4019661397)},[_c('recurring-targets',{attrs:{"targets":_vm.invoice.targets},on:{"delete-subscription-click":_vm.deleteRecurringInvoice}})],1):_vm._e(),_c('select-input',{staticClass:"mt-5",attrs:{"setup":{
      'func': _vm.setTargetIds,
      'args': {
        'model': _vm.payMethods.config.target_id,
        'key': _vm.payMethods.targetIdKey,
        'prefix': 'payment_',
        'options': _vm.chatIds,
        'multiple': true,
        'selectAll': true
      },
    }}}),(_vm.payMethods && _vm.payMethods.config.target_id.chat_ids.length > 1)?_c('a-alert',{attrs:{"show-icon":""}},[_c('a-icon',{staticClass:"text-danger",attrs:{"slot":"icon","type":"shopping-cart"},slot:"icon"}),_c('div',{attrs:{"slot":"message"},slot:"message"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('multiple_payments_total_groups_message', {groups: _vm.payMethods.config.target_id.chat_ids.length, sale: _vm.payInfo.sale }))}})]),_c('ul',{staticClass:"mb-0",attrs:{"slot":"description"},slot:"description"},_vm._l((_vm.payInfo.tariffs),function(info,index){return _c('li',{key:index,staticClass:"mb-1 last:mb-0",domProps:{"innerHTML":_vm._s(_vm.$tc('multiple_payments_tariff_total_message', info.monthCount, {
          tariff: info.name,
          month: info.monthCount,
          total: info.total,
          currency: info.currency_symbol,
        }))}})}),0)],1):_vm._e(),_c('services-cart',{staticClass:"mt-5",on:{"update-pay-info":_vm.updatePayInfo,"on-promocode-activate":_vm.handlePromo},model:{value:(_vm.payMethods),callback:function ($$v) {_vm.payMethods=$$v},expression:"payMethods"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }