










































































import { successNotification } from '@/includes/NotificationService'
import { BaseChatInfo } from '@/includes/types/Chat/types'
import ChatLicense from "@/includes/logic/ChatLicense";
import RecurringInvoicesMixin from "@/includes/logic/RecurringInvoices/RecurringInvoicesMixin";

import ServicesCart from 'piramis-base-components/src/plugins/AdminTemplate/pages/services-cart.vue'
import { FieldData, SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import { PayInfos } from 'piramis-base-components/src/components/ServicesCart/logic/types'
import AvatarsRow from 'piramis-base-components/src/components/AvatarsRow.vue'
import RecurringTargets from 'piramis-base-components/src/components/RecurringTargets.vue';

import { Component, Mixins } from 'vue-property-decorator'
import { upperFirst } from "lodash";

@Component({
  methods: { upperFirst },
  components: {
    PageTitle,
    ServicesCart,
    AvatarsRow,
    RecurringTargets
  }
})
export default class BuyChatLicense extends Mixins(UseFields, RecurringInvoicesMixin) {
  payMethods = this.defaultPayMethods()

  chatLicense: ChatLicense = new ChatLicense(this.$store.state.chatState.chat)

  payInfo: PayInfos | null = null

  updatePayInfo(data: PayInfos) {
    this.payInfo = data
  }

  setTargetIds(args: FieldData) {
    args.setter = (value: Array<number>) => {
      if (!value.length) {
        this.$set(args.model, args.key, [ this.$store.getters.chatId ])
      } else {
        if (value.includes(this.$store.getters.chatId)) {
          this.$set(args.model, args.key, value)
        }
      }
    }

    return args
  }

  get chatIds(): Array<SelectOptionData> {
    return ((this.$store.state.chatState.chatsInfo?.chats ?? []) as Array<BaseChatInfo>)
      .map(c => ({
        label: c.group_title,
        value: c.chat_id,
        image: {
          src: c.group_photo
        }
      }))
  }

  mounted() {
    this.$baseTemplate.saveButton.hide()
  }

  defaultPayMethods() {
    return {
      targetIdKey: 'chat_ids',
      config: {
        target_id: {
          chat_ids: [ +this.$store.getters.chatId ]
        },
        target_type: 'chat'
      }
    }
  }

  handlePromo(data: any) {
    if (data && 'promocode_success' in data) {
      this.$baseTemplate.loader.open()

      this.$store.dispatch('requestChatConfig', { id: this.$store.getters.chatId })
        .then(() => {
          this.$baseTemplate.sidebar.reload()
        })
        .finally(() => {
          this.$baseTemplate.loader.close()
        })

      successNotification(this.$t('promocode_activated_success_title', [ data.promocode_success ]).toString())
    }
  }
}
